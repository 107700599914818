import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';

const AboutPage = ({ data }) => (
  <Layout title="About the Program">
    <Hero mediaSource="about-hero.png" className="mb-0 about-hero">
      <Container className="about-hero__content">
        <Image
          filename="about-hero-cosmos-leftside.png"
          className="about-hero__content__cosmos-leftside"
        />
        <Image
          filename="about-hero-astronaut.png"
          className="about-hero__content__astronaut"
        />
        <h1 className="title about-hero__content__title">About</h1>
        <Image
          filename="about-hero-cosmos-rightside.png"
          className="about-hero__content__cosmos-rightside"
        />
      </Container>
    </Hero>
    <Section className="about-mission">
      <Container>
        <h1 className="mb-1">About the Program</h1>
      </Container>
      <Container className="about-mission__container">
        <Image
          filename="about-img-girlreading.png"
          className="about-mission__container__first-image"
        />
        <div className="about-mission__container__literacy-text">
          <h2>Discover Literacy</h2>
          <h3>
            A few minutes of literacy learning a day contributes to a lifetime
            of success.
          </h3>
          <p>
            Discover Literacy resources enable educators to implement the five
            foundational components of literacy into interactive, on-the-go
            learning experiences using research-based instructional strategies.
            Flexibly designed for students at all levels, these no-cost
            resources are easy to use with little-to-no prep required. Through
            this educational initiative, The Dollar General Literacy Foundation,
            Discovery Education, and the National Afterschool Association are
            committed to providing equitable access to literacy education for
            students from every background—especially those in under-resourced
            communities.
          </p>
        </div>
        <Image
          filename="about-img-childrenreading.png"
          className="about-mission__container__second-image"
        />
        <div className="about-mission__container__microlearnings-text">
          <h2>Big Ideas & Microlearnings</h2>
          <h3>
            Literacy resources streamlined for flexible learning experiences.
          </h3>
          <p>
            Discovery Literacy resources contain microlearnings, which are
            small, digestible modules that teachers and parents can complete on
            their own time. These microlearnings will explore how to best
            implement instruction grounded in the five big ideas of literacy.
          </p>
        </div>

        <Image
          filename="about-cloud.svg"
          className="about-mission__container__cloud-image"
          objectFit="fill"
        />

        <Image
          filename="about-mission-astronaut.svg"
          className="about-mission__container__astronaut-image"
        />
      </Container>
    </Section>
    <Section className="about-partners">
      <Container className="about-partners__container">
        <h1 className="about-partners__container__title">Our Partners</h1>
        <Row className="mb-4">
          <Column size={3} className="pr-4">
            <Image
              filename="about-discovery-logo.png"
              alt="Discovery Education logo"
            />
          </Column>
          <Column size={9}>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc., Discovery
              Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners.
            </p>
            <Button
              to="https://discoveryeducation.com"
              target="_blank"
              className="text-bold"
            >
              <Icon name="externallink" marginRight />
              Visit DiscoveryEducation.com
            </Button>
          </Column>
        </Row>
        <Row className="mb-4">
          <Column size={3}>
            <Image
              filename="about-literacyfoundation-logo.svg"
              alt="Dollar General Literacy Foundation logo"
            />
          </Column>
          <Column size={9}>
            <p>
              The Dollar General Literacy Foundation is proud to support
              initiatives that help others improve their lives through literacy
              and education. Since 1993, the Foundation has awarded more than
              $216 million in grants to nonprofit organizations, helping more
              than 15.4 million individuals take their first steps toward
              literacy, a general education diploma or English proficiency. Each
              year, the Dollar General Literacy Foundation provides financial
              support to schools, nonprofit organizations and libraries within a
              15-mile radius of Dollar General stores and distribution centers.
            </p>
            <Button
              to="https://dgliteracy.org"
              className="text-bold"
              target="_blank"
            >
              <Icon name="externallink" marginRight />
              Visit dgliteracy.org
            </Button>
          </Column>
        </Row>
        <Row>
          <Column size={3}>
            <Image
              filename="about-afterschool-logo.svg"
              alt="National After School Association logo"
            />
          </Column>
          <Column size={9}>
            <p>
              The National Afterschool Association is the national professional
              membership association for people who work with and on behalf of
              youth during out-of-school time. NAA uses its purpose, position,
              and partnerships to build equity and connections through essential
              strategic areas to build the skills of afterschool professionals
              and leaders and to create a strong, valued profession. NAA’s four
              strategic focus areas—field leadership, professional development,
              {/* advocacy, and recognition—are designed for: <br />
              <br /> */}
            </p>
            <ul>
              <li>
                <p>
                  Creating, contributing to, and fostering partnerships that
                  promote professional and program standards.
                </p>
              </li>
              <li>
                <p>
                  Developing and recognizing afterschool professionals and
                  leaders.
                </p>
              </li>
              <li>
                <p>
                  Fostering partnerships and policies that support the
                  development of people who work to provide high-quality
                  afterschool opportunities for young people, recognizing and
                  promoting the important contribution of the afterschool
                  profession and its professionals.
                </p>
              </li>
            </ul>
            <p>
              {/* <br /> */}
              NAA membership includes more than 30,000 professionals who reach
              roughly 600,000 youth through community-based, school-based and
              private afterschool programs.
            </p>
            <Button
              target="_blank"
              to="https://naaweb.org"
              className="text-bold"
            >
              <Icon name="externallink" marginRight />
              Visit naaweb.org
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about-resources">
      <Container className="about-resources__container">
        <h1 className="mb-1">Turnkey Literacy Resources</h1>
        <Row>
          <Column size={4}>
            <Image filename="about-resources-educator.png" />
            <h2 className="mt-1">Educator Resources</h2>
            <h3 className="mt-1">For in-person or remote learning.</h3>
            <p className="pb-4">
              Educators can find ways to incorporate literacy activations into
              existing curriculum while deepening their own understanding of
              literacy skills.
            </p>
            <Button
              to="/literacy-approach"
              className="about-resources__container__buttons mt-1"
            >
              Learn More
            </Button>
          </Column>
          <Column size={4}>
            <Image filename="about-resources-timely.png" />
            <h2 className="mt-1">Timely Microlearnings</h2>
            <h3 className="mt-1">A little bit goes a long way.</h3>
            <p className="pb-4">
              Educators can upskill their literacy strategies every day with
              professional learning resources readily available at their
              fingertips.
            </p>
            <Button
              to="/classroom-resources#phonemic-awareness"
              className="about-resources__container__buttons mt-1"
            >
              Learn More
            </Button>
          </Column>
          <Column size={4}>
            <Image filename="about-resources-family.png" />
            <h2 className="mt-1">Family Resources</h2>
            <h3 className="mt-1">Create connections beyond classrooms.</h3>
            <p className="pb-4">
              Caregivers and family members can help students reinforce
              essential literacy skills using collaborative activities designed
              for home learning.
            </p>
            <Button
              to="/classroom-resources"
              className="about-resources__container__buttons mt-1"
            >
              Learn More
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="about-quote">
      <Container className="about-quote__container">
        <Image
          filename="about-quote-astronaut.png"
          className="about-quote__container__astronaut-image"
        />
        <div className="about-quote__container__text">
          <h1 className="mb-2">
            50% of adult Americans cannot read a book written at an eighth-grade
            level.
          </h1>
          <p className="large">—National Institute for Literacy</p>
        </div>
        <Image
          filename="about-quote-bulb.png"
          className="about-quote__container__bulb-image"
        />
      </Container>
    </Section>
  </Layout>
);

export default AboutPage;
